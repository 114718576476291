import { Injectable } from '@angular/core'
import { HttpBackend, HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import {
  FleetCustomerGetDataResponse,
  FleetOrderDetailsColumns,
  FleetCustomerUpdateResponse,
  FleetCustomerLastSeenVersionsUpdateModel,
  UpdatePreferencesPayload,
  UpdatePreferencesResponse,
} from '../models/fleet-customer.models'
import {
  CheckDoesCustomerExistResponse,
  AddUserSuccessResponse,
  UpdateUserSuccessResponse,
  FindUsersDataSuccessResponse,
} from '../../users-page/users-index/store/users-index.actions'
import { AuthorizedFleetCustomerExtended } from '../../users-page/users-index/components/users-list/users-list.component'
import { UnsubscribeData } from '../../unsubscribe/unsubscribe-page.actions'

import { environment as ENV } from '../../../environments/environment'
import { FleetCustomer } from '@boosterfuels/types/types-ts'

export interface FleetCustomerUpdate {
  _id: string
  firstName: string
  lastName: string
  telephone?: string
}

@Injectable()
export class FleetCustomerApi {
  private httpNoInterceptors: HttpClient

  constructor(
    private http: HttpClient,
    handler: HttpBackend
  ) {
    this.httpNoInterceptors = new HttpClient(handler)
  }

  getData() {
    return this.http.get<FleetCustomerGetDataResponse>('/FleetCustomer/getData')
  }

  findAdminUsersForFleetAccount(fleetAccountId) {
    return this.http.get<{ fleetCustomers: FleetCustomer[] }>(
      '/FleetCustomer/findAdminUsersForFleetAccount',
      { params: { fleetAccountId } }
    )
  }

  findUsersData() {
    return this.http.get<FindUsersDataSuccessResponse>(
      '/FleetCustomer/findConsumerTeamManagementData'
    )
  }

  updateFleetOrderDetailsColumns(
    _id: string,
    fleetOrderDetailsColumns: FleetOrderDetailsColumns,
    fleetCompanyId: string
  ) {
    return this.http.put<FleetCustomerUpdateResponse>(
      '/FleetCustomer/updateFleetOrderDetailsColumns',
      { _id, fleetOrderDetailsColumns },
      { params: { fleetCompanyId } }
    )
  }

  updateLastSeenVersions(data: FleetCustomerLastSeenVersionsUpdateModel) {
    return this.http.put<FleetCustomerUpdateResponse>(
      '/FleetCustomer/updateLastSeenVersions',
      data
    )
  }

  checkDoesCustomerExist(email: string) {
    return this.http.post<CheckDoesCustomerExistResponse>(
      '/FleetCustomer/checkDoesFleetCustomerWithFleetRoleExist',
      { email }
    )
  }

  addUser(data: any) {
    return this.http.post<AddUserSuccessResponse>(
      '/FleetCustomer/addTeamMember',
      data
    )
  }

  registerUser(data: any) {
    return this.http.post<AddUserSuccessResponse>(
      '/FleetCustomer/register',
      data
    )
  }

  removeUser(data: any) {
    return this.http.post<{ fleetCustomer: AuthorizedFleetCustomerExtended }>(
      '/FleetCustomer/removeTeamMember',
      data
    )
  }

  updateUser(data: AuthorizedFleetCustomerExtended) {
    return this.http.put<UpdateUserSuccessResponse>(
      '/FleetCustomer/updateTeamMember',
      data
    )
  }

  update(data: FleetCustomerUpdate) {
    return this.http.put<FleetCustomer>(
      `/FleetCustomer/updateFleetCustomer/${data._id}`,
      { fleetCustomer: data }
    )
  }

  updateNotificationPreferences(
    data: UpdatePreferencesPayload
  ): Observable<UpdatePreferencesResponse> {
    return this.http.put<UpdatePreferencesResponse>(
      '/FleetCustomer/updateNotificationPreferences',
      data
    )
  }

  unsubscribeEmail(data: UnsubscribeData): Observable<{ name: string }> {
    const url = `${ENV.BASE_API_URL}${ENV.APP_API_URL}/FleetCustomer/unsubscribeEmail`
    return this.httpNoInterceptors.post<{ name: string }>(url, data)
  }
}
